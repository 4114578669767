import React from "react";
import { Route, Routes } from "react-router-dom/dist";
import Chat from "./Pages/Chat";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'; // The file path may vary
Amplify.configure(awsExports);


const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/demo" element={<Chat />} />
      </Routes>
    </div>
  );
};

export default App;
