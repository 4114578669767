import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fields from "../../Components/Fields.js";
import QueryUI from "../../Components/Query.js";
import RoleView from "../../Components/Roles.js";
import LogsView from "../../Components/Logs.js";
import { Button,ButtonGroup, Dropdown } from "react-bootstrap";

import { Authenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { Heading, useTheme, View
    ,Button as CustomButton
    , Text
} from "@aws-amplify/ui-react";


const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
};

const components = {
  SignIn : {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      // const contactUs = () => {
      //   window.location.href = 'https://mlexperts.ai/contactus';
      // };

      return (
        <View textAlign="center" padding="0px 0px 10px 0px">
          <Text fontWeight="bold" style={{ fontSize: '14px' }}>Interested in exploring our demo but don't have access?</Text>
          <Text fontWeight="bold" style={{ fontSize: '14px' }}>Simply <a href="https://mlexperts.ai/contactus" style={{ fontWeight: 'bold' }}>contact our team</a> for assistance, and we'll help you get started.</Text>

          {/* <CustomButton
            fontWeight="normal"
            onClick={contactUs}
            size="medium"
            variation="link"
          >
            Contact us
          </CustomButton> */}
        </View>
      );

    },
  }
};


const  Chat = () => {
    const [activeItem, setActiveItem] = useState('chat');
    const environ = process.env.ENVIRONMENT

    const handleItemClick = (name) => setActiveItem(name);

    return (
        <Authenticator  
        variation="modal"     
        formFields={formFields}
        components={components}
        hideSignUp={true}
      >
        <div style={{ backgroundColor: '#f8f9fa', minHeight: '100vh' }} className="container mt-3">

            <ButtonGroup className="mb-3">
                <Button
                    variant={activeItem === 'schema' ? 'primary' : 'secondary'}
                    onClick={() => handleItemClick('schema')}
                >
                    Schema
                </Button>

                <Button
                    variant={activeItem === 'chat' ? 'primary' : 'secondary'}
                    onClick={() => handleItemClick('chat')}
                >
                    Chat
                </Button>

                <Button
                    variant={activeItem === 'roles' ? 'primary' : 'secondary'}
                    onClick={() => handleItemClick('roles')}
                >
                    Roles
                </Button>

                <Button
                    variant={activeItem === 'logs' ? 'primary' : 'secondary'}
                    onClick={() => handleItemClick('logs')}
                >
                    Logs
                </Button>
            </ButtonGroup>

            {activeItem === 'schema' && <Fields />}
            {activeItem === 'chat' && <QueryUI />}
            {activeItem === 'roles' && <RoleView />}
            {activeItem === 'logs' && <LogsView />}
        </div>
    </Authenticator>
    );
}

export default Chat;
