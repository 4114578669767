import React from 'react';
import { Table, Badge } from 'react-bootstrap';

const RoleView = () => {
    const roles = [
        {
            "organization_id": 3,
            "collection_name": "applications",
            "operation": ["read"],
            "fields": ["__all__"]
        },
        {
            "organization_id": 5,
            "collection_name": "applications",
            "operation": ["read"],
            "fields": ["name", "is_archived", "avg_monthly_spend", "category"]
        },
        {
            "organization_id": 3,
            "collection_name": "applications",
            "operation": ["read"],
            "fields": []
        }
    ];
    let names = ["Mausam", "Sita", "Hari"];
    return (
        <div>
            {roles.map((role, index) => (
                <div key={index} style={{ padding: '10px', marginBottom: '20px' }}>
                    <h2>{names[index]}</h2>
                    <Table striped bordered hover responsive>
                        <tbody>
                            {Object.entries(role).map(([key, value]) => (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td>
                                        {Array.isArray(value) ? (
                                            value.map((item, i) => (
                                                <Badge key={i} variant="secondary" style={{ marginRight: '5px' }}>
                                                    {item}
                                                </Badge>
                                            ))
                                        ) : (
                                            value
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}
        </div>
    );
};

export default RoleView;
