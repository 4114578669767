import React from 'react';
import { Table, Badge } from 'react-bootstrap';

const Fields = () => {
    const collection = "applications";

    // Updated schema fields including nested fields
    const fields = [
        { name: '_id', type: 'Number', description: 'Unique identifier of the application collection' },
        { name: 'name', type: 'String', description: 'Name of the application' },
        { name: 'org_id', type: 'Number', description: 'Which organisation is the application associated with' },
        { name: 'is_archived', type: 'bool', description: 'Whether an application is archived.' },
        { name: 'app_avg_spend', type: 'Number', description: 'The average monthly spendings on the application in the current financial year.' },
        { name: 'category', type: 'String', description: 'The category of application. Category can be \'A\' to \'E\'' },
        { name: 'renew_date', type: 'Date', description: 'The most recent licence renewal date out of all the licences' },
        { name: 'auto_renewal', type: 'bool', description: 'Whether the licences gets renewed automatically or not.' },
        { name: 'risk_level', type: 'Number', description: 'The risk level score from 0-5.' },
        { name: 'app_user_count', type: 'Number', description: 'The total number of users in the organisation using the application' },
        { name: 'app_usage', type: 'Number', description: 'The number of times the application was used across this current financial year.' },
        { name: 'total_spend', type: 'Number', description: 'Total spend in the application for all the users associated with the application.' },
        { name: 'avg_monthly_spend', type: 'Number', description: 'Average monthly spend on the application by the users associated with application' },
        { name: 'app_external_user_count', type: 'Number', description: 'The number of users who don\'t belong to the organisation but still use the application.' },
        { name: 'app_user_with_license_count', type: 'Number', description: 'Number of users who use applications with a licence' },
        { name: 'app_user_without_license_count', type: 'Number', description: 'Number of users who use applications without a licence' },
        { name: 'archived_app_user_count', type: 'Number', description: 'number of users associated with archived application' },
        { name: 'active_app_user_count', type: 'Number', description: 'Number of users associated with active application' },
        {
            name: 'departments', type: 'Array', description: 'List of departments of users which use the application.', 
            fields: [
                { name: 'id', type: 'Number', description: 'Unique identifier of the department' },
                { name: 'name', type: 'String', description: 'Name of department' },
                { name: 'total_spend', type: 'Number', description: 'total spend in the application by the users belonging to specific department' },
                { name: 'user_counts', type: 'Number', description: 'number of users using application in the respective department' },
                {
                    name: 'users', type: 'Array', description: 'List of users that belong to the particular department and are using the application.',
                    fields: [
                        { name: 'name', type: 'String', description: 'Name of the user' },
                        { name: 'total_spend', type: 'Number', description: 'total spend in the application by the user belonging to specific department' },
                        { name: 'id', type: 'Number', description: 'unique identifier of the user' }
                    ]
                },
            ]
        },
        {
            name: 'app_source_details', type: 'Array', description: 'List of sources for integrating the application.',
            fields: [
                { name: 'keyword', type: 'String', description: 'Name of source. Example are slack, gsuite, azure' },
                { name: 'status', type: 'String', description: 'If the source is active or not.' },
                { name: 'is_primary', type: 'bool', description: 'Field that specifies if it is the primary source of integration.' }
            ]
        },
    ];

    const renderNestedFields = (nestedFields, level = 0) => {
        return nestedFields.map((field, index) => (
            <React.Fragment key={index}>
                <tr>
                    <td style={{ paddingLeft: `${level * 20}px`, borderRight: 'none' }}>
                        <Badge variant={level === 0 ? "secondary" : "info"}>{field.name}</Badge>
                    </td>
                    <td style={{ borderLeft: 'none', borderRight: 'none' }}>{field.type}</td>
                    <td style={{ borderLeft: 'none' }}>{field.description}</td>
                </tr>
                {field.fields && (
                    <tr>
                        <td colSpan="3" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: '10px', paddingRight: '10px', border: 'none' }}>
                            <Table size="sm" bordered>
                                <tbody>
                                    {renderNestedFields(field.fields, level + 1)}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        ));
    };

    return (
        <div>
            <h1>Collection: {collection}</h1>

            <p>
                The collection <strong>applications</strong> consists of data related to the SAAS applications 
                that are being used by various organisations. Each document in the MongoDB collection 
                <strong> application</strong> is information related to the application that is being used 
                by a certain organisation.
            </p>
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Element Name</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((field, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td><Badge variant="secondary">{field.name}</Badge></td>
                                <td>{field.type}</td>
                                <td>{field.description}</td>
                            </tr>
                            {field.fields && (
                                <tr>
                                    <td colSpan="3" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: '10px', paddingRight: '10px', border: 'none' }}>
                                        <Table size="sm" bordered>
                                            <tbody>
                                                {renderNestedFields(field.fields, 1)}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        </div>
    );

};

export default Fields;
