import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Badge } from 'react-bootstrap';

const userOptions = [
    { key: 'user1', value: '788', text: 'Mausam' },
    { key: 'user2', value: '558', text: 'Sita' },
    { key: 'user3', value: '400', text: 'Hari' },
];
function QueryUI() {
    const [selectedUser, setSelectedUser] = useState('788');
    const [queryText, setQueryText] = useState('');
    const [intentCaptured, setIntentCaptured] = useState('');
    const [mongoQuery, setMongoQuery] = useState('');
    const [outputJson, setOutputJson] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [review, setReview] = useState("none")
    const [log_id, setLogId] = useState()
    const base_url = process.env.REACT_APP_BACKEND_URL
    const exampleQueries = [
        "List me top 10 applications name which doesn’t get renewed automatically ordered by name ",
        "Average, max, min spend across applications by risk level",
        "Delete the data of applications which has total spending more than 1 lakh",
        "Check whether the applications have auto-renewal enabled. If auto-renewal is enabled then disable it.",
        "Return me the app that has highest number of archived users.",
        "give me spend of all categories",
        "total spend of top 5 most used applications",
        "List the departments of the application 'SynerGex' where spend is greater than 750",
        "List the name of the user along with the department name who is spending highest amount in application 'SynerGex'",
        "Calculate the average usage across applications. Find 5 applications whose usage is more than this",
        "Provide me name of the apps with most usage and most spend.",
        "Give me list of all apps with spend in the top 20 percentile?",
        "List the departments that has more users than average user count of the departments and also their total spend must be more than average spend of the applications."
    ];
    const exampleTypes = [
        "Simple Filter",
        "Simple Aggregation",
        "RBAC",
        "RBAC",
        "Simple Aggregation",
        "Complex Aggregation",
        "Complex Aggregation",
        "Nested JSON",
        "Nested JSON",
        "Failed Query",
        "Failed Query",
        "Failed Query",
        "Failed NestedJSON Query"
        ]

    const [selectedExampleQuery, setSelectedExampleQuery] = useState('');
    const handleLike = async () => {
        const apiUrl = `${base_url}/api/log?log_id=${log_id}&user_response=like`
        if (!log_id) {
            console.log("No log id");
            return
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            alert("Liked")
            setReview("like")
        };


    };
    const handleExampleQueryClick = (query) => {
        setQueryText(query);
    };
    const handleDislike = async () => {
        const apiUrl = `${base_url}/api/log?log_id=${log_id}&user_response=dislike`
        if (!log_id) {
            console.log("No log id");
            return
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            alert("Disliked")
            setReview("dislike")
        };

    }
    function displayIntent(intent) {
        if (intent) {
            // try {
            //     const parsedIntent = JSON.parse(intent);
            //     return <pre>{JSON.stringify(parsedIntent, null, 2)}</pre>;
            // } catch (error) {
            //     return intent; // Not a JSON string, so just return it
            // }
            try{
                if (typeof intent === 'object') {
                    // If intent is an object, convert it to a JSON string
                    return <pre>{JSON.stringify(intent, null,1)}</pre>;
                } else {
                    // If intent is not an object, convert it to a string
                    return String(intent);
                }
            } catch (error) {
                console.log("error intent", intent)
                return ''; // Not a JSON string, so just return it
            }
        }
        return ''; // Intent is null
    }
    const handleExampleQuerySelect = (query) => {
        setQueryText(query);
    };
    const handleQuerySubmit = async () => {
        try {
            setLoading(true);
            setError(false)
            let client_id = selectedUser
            const apiUrl = `${base_url}/api/query?query=${encodeURIComponent(queryText)}&client_id=${client_id}`;

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data["status_code"] >= 400) {
                    setOutputJson(`${data["error_type"]}\n:${data["error_message"]}`)
                    setIntentCaptured("");
                    setMongoQuery("");
                    setError(true);
                    setLogId(data["log_id"]);
                    return
                }
                setIntentCaptured(data.intent);
                setMongoQuery(data["mongo-query"]);
                let mongo_response = data["mongo-response"]
                if (mongo_response.trim() == "") {
                    mongo_response = "[No Results Found]"
                }
                setOutputJson(mongo_response);
                setLogId(data["log_id"]);
                setError(false);
            } else {
                setError(true)
                setOutputJson("Error in fetching");
                console.error('API call failed');
            }
        } catch (error) {
            console.error('Error during API call:', error);
        } finally {
            setLoading(false);
        }
    };

    function displayIntent(intent) {
        if (intent) {
            // try {
            //     const parsedIntent = JSON.parse(intent);
            //     return <pre>{JSON.stringify(parsedIntent, null, 2)}</pre>;
            // } catch (error) {
            //     return intent; // Not a JSON string, so just return it
            // }
            try{
                if (typeof intent === 'object') {
                    // If intent is an object, convert it to a JSON string
                    return JSON.stringify(intent, null,1);
                } else {
                    // If intent is not an object, convert it to a string
                    return String(intent);
                }
            } catch (error) {
                console.log("error intent", intent)
                return ''; // Not a JSON string, so just return it
            }
        }
        return ''; // Intent is null
    }

    return (
        <Container>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <h2>User</h2>
                    <Form.Control
                        as="select"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="" disabled>Select a user from multiple options</option>
                        {userOptions.map(option => (
                            <option key={option.key} value={option.value}>{option.text}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <h2>Query</h2>
                    <Form.Control
                        as="textarea"
                        placeholder="Enter your query here "
                        value={queryText}
                        onChange={(e) => setQueryText(e.target.value)}
                        rows={2}
                    />
                    <br />
                    <Button variant="primary" onClick={handleQuerySubmit} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col><h2>Example Queries</h2></Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    {exampleQueries.map((example, index) => (
                    <Button
                    key={index}
                    variant="outline-secondary"
                    onClick={() => handleExampleQueryClick(example)}
                    style={{ 
                        marginBottom: '10px', 
                        width: '100%', 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between' 
                    }}
                    >
                    <Badge 
                        style={{ 
                            backgroundColor: '#007bff', // Soft Blue
                            color: 'white', // White text color for better readability
                            flexShrink: 0 
                        }}
                                    >
                        {exampleTypes[index]}
                    </Badge>
                    <div style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '10px' }}>
                        {example}
                    </div>
                    </Button>

                    ))}
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <h2>Intent Captured</h2>
                    <Form.Control
                        as="textarea"
                        value={displayIntent(intentCaptured)}
                        onChange={(e) => setIntentCaptured(e.target.value)}
                        rows={8}
                    />
                </Col>
                <Col>
                    <h2>Mongo Query</h2>
                    <Form.Control
                        as="textarea"
                        value={mongoQuery}
                        onChange={(e) => setMongoQuery(e.target.value)}
                        rows={8}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <h2>Output</h2>
                    <Form.Control
                        as="textarea"
                        value={outputJson}
                        onChange={(e) => setOutputJson(e.target.value)}
                        rows={16}
                        style={{ borderColor: error ? 'red' : '' }}
                    />
                    <div style={{ marginTop: '10px' }}>
                        <Button variant="success" onClick={handleLike}>
                            Like
                        </Button>{' '}
                        <Button variant="danger" onClick={handleDislike}>
                            Dislike
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default QueryUI;
